@page {
  size: legal;
}

@page:left{
  @bottom-right {
    content: "Page " counter(page) " of " counter(pages);
  }
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background: #eee;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, ".SFNSText-Regular",
    sans-serif;
    font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
  margin: 0;
  line-height: 1.5;
  color: #2c353d;
}

textarea,
input,
select,
button,
.button {
  font-size: 1rem;
  font-family: inherit;
  border: none;
  border-radius: 8px;
  padding: 0.5rem 0.75rem;
  box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.2), 0 1px 2px hsla(0, 0%, 0%, 0.2);
  background-color: white;
  line-height: 1.5;
  margin: 0;
  resize: none;
  transition: .2s;
}

input[type='radio'] {
  box-shadow: none !important;
}

input[type='file'] {
  display: none;
}

input.search {
  width: 350px;
}

button,
.button {
  color: rgb(93, 151, 256);
  font-weight: 500;
  cursor: pointer;
  display: inline-block;
}

button.primary,
.button.primary {
  background-color: #dddace;
  color: #2c353d;
}

.button.primary span.loader,
button.primary span.loader {
  vertical-align: middle;
  margin-left: 8px;
  margin-right: -50px;
}

button.secondary,
.button.secondary {
  background-color: #b3aea8;
  color: #fff;
}

button.decline,
.button.decline {
  background-color: #6c757d;
  color: #fff;
}

textarea:hover:not(.disabled),
input:hover:not(:disabled):not([type='file']),
button:hover:not(:disabled),
.button:hover {
  box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.6), 0 1px 2px hsla(0, 0%, 0%, 0.2);
}

button:active:not(:disabled),
.button:active {
  box-shadow: 0 0px 1px hsla(0, 0%, 0%, 0.4);
  transform: translateY(1px);
}

button:disabled {
  color: #818181;
}

#root {
  display: flex;
  height: 100%;
  width: 100%;
}

#detail {
  flex: 1;
  width: 100%;
  background-color: #fbfbfb;
  overflow-y: auto;
}

#detail.loading {
  opacity: 0.25;
  transition: opacity 200ms;
  transition-delay: 200ms;
}

hr {
  border: none;
  height: 1px;
  background-color: #ddd;
}

.card {
  background-color: #fff;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, .2);
  border-radius: 5px;
  padding: 15px;
  width: 300px;
}

.card.inset {
  box-shadow: 1px 0px 5px rgba(0, 0, 0, .2) inset;
  border-radius: 10px;
}

.card.spaced {
  margin-bottom: 10px;
}

.card > h2 {
  margin-top: 0px;
}

.card select {
  margin-top: 10px;
}

.card .auth-selection-text {
  margin-top: 10px;
  font-size: 13px;
}

.card .auth-selection-text > div {
  margin-top: 10px;
}

select.inline-dropdown {
  font-size: inherit;
  padding: 2px;
  margin: 0px 5px;
}

.container {
  position: relative;
  padding: 2rem 4rem;
  margin-top: 50px;
}

.action-button {
  background-color: #eee;
  border: 0;
  padding: 8px 12px;
  position: relative;
  margin-left: 5px;
  border-radius: 15px;
  color: #222;
  display: inline-block;
  cursor: pointer;
}

.action-button:hover {
  background-color: #ddd;
  color: #000;
}

button.action-button:disabled {
  opacity: 0.6;
  background: #eee;
  cursor: not-allowed;
}

.other h2 {
  font-size: 18px;
  color: #444;
  margin-bottom: 7px;
}

.other a {
  color: #777;
  text-decoration: underline;
  font-size: 14px;
}

.other ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

h1 {
  font-size: 24px;
  color: #333;
}

pre::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

pre::-webkit-scrollbar-thumb {
  background: #999;
}

.uploadStatus {
  display: inline-block;
  margin-left: 10px;
  color: #36d7b7;
  vertical-align: middle;
}

.uploadError {
  margin-top: 10px;
}

.errorIcon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 2px;
  margin-top: 1px;
}

.link {
  cursor: pointer;
  color: rgb(93, 151, 256);
  transition: .2s;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.text-centered {
  text-align: center;
}

.loading-icon span {
  margin: 0px auto;
}

.centered {
  margin: 0px auto;
  vertical-align: middle;
}

table {
  margin: 0px auto;
  text-align: left;
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border-collapse: collapse;
}

table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

table td, table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

#login {
  width: 100%;
  height: 100%;
}

.logo {
  margin: 100px auto 20px;
  font-size: 30px;
  width: 300px;
  text-align: center;
  color: #2c353d;
}

.logo-w img {
  width: 55px;
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 1px;
}

#login .login-card {
  margin: 0px auto;
  width: 396px;
}

.login-card .description {
  color: #323252;
}

.login-card input,
.login-card textarea,
.login-card button {
  width: 100%;
  margin: 6px 0px;
}

.login-card input,
.login-card textarea {
  box-shadow:  0 0 2px hsla(0, 0%, 0%, 0.2) inset;
  border: 1px solid #eee;
}

.login-card input:hover:not(:disabled):not([type='file']),
.login-card textarea:hover:not(:disabled) {
  box-shadow: 0 0 3px hsla(0, 0%, 0%, 0.2) inset;
}

.login-card .split-input input {
  width: calc(50% - 5px);
  margin-right: 5px;
}

.login-card .split-input input:last-child {
  margin-left: 5px;
  margin-right: 0px;
}

.login-card .action-buttons {
  float: right;
  height: 50px;
}

.login-card .action-buttons button,
.login-card .action-buttons .button {
  width: auto;
  margin-left: 8px;
}

.clearfix {
  zoom: 1;
  height: 50px;
}

.login-card .login-link {
  padding: 10px 0px 2px;
}

.text-divider {
  margin: 10px 8px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  color: #96999e;
}

.text-divider > span {
  position: relative;
}

.text-divider > span:before,
.text-divider > span:after {
  background: #d7dade;
  content: '';
  height: 1px;
  position: absolute;
  top: 50%;
  width: 9999px;
}

.text-divider > span:before {
  margin-right: 15px;
  right: 100%;
}

.text-divider > span:after {
  margin-left: 15px;
  left: 100%;
}

.status {
  border: 1px solid transparent;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border-radius: 8px;
}

.status.success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.status.error {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.notFound {
  margin: 0px auto;
}